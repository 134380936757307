@font-face {
  font-family: "Spoqa Han Sans Neo";
  src: local("SpoqaHanSansNeo")
    url("../fonts/SpoqaHanSansNeo-Bold.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Honor Sans";
  src: local("HonorSans")
    url("../fonts/HONORSans-Regular.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}

body {
	font-family: "Spoqa Han Sans Neo", "Honor Sans", sans-serif !important;
  font-weight: 500;
  word-break: keep-all;
}

p {
  margin-bottom: 0;
}

.text-huge {
  font-size: calc(1.275rem + 0.3vw);
}

.text-big {
  font-size: 1.25rem;
}

.text-small {
  font-size: 0.875em;
}

.text-tiny {
  font-size: 0.5rem;
}
