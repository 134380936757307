figure {
  text-align: center;
}

figure img {
  max-width: 100%;  
}

p img {
  max-width: 100%;  
}